import Repository from "./Repository";

const resource = "/otp";

export default {
	/**
	 * Ejecuta una solicitud de registro de usuario.
	 *
	 * @param  {Object} payload
	 * @return {Promise}
	 */
	create(payload) {
		return Repository.post(resource, payload);
	},
	/**
	 * Ejecuta una solicitud de la informacion del estado de un código
	 *
	 * @return {Promise}
	 */
	status(id) {
		return Repository.get(`${resource}/${id}/status`);
	},
}
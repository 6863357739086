<template>
	<layouts-identify :buttonBack="{ path: '/identify' }">
		<div class="pt-7 pb-4">
			<h3 class="ls-tight fw-bolder h3">Confirmación de seguridad</h3>
			<p v-if="target_type == 'phone'">Ingresa el código que enviamos por <b>whatsapp</b> a: <b class="d-inline-block">{{ targetFormatted }}</b></p>
			<p v-else-if="target_type == 'email'">Ingresa el código que enviamos por <b>email</b> a: <b class="d-inline-block">{{ targetFormatted }}</b></p>
		</div>
		<form class="vstack gap-5" @submit.prevent="submit">
			<div class="text-center">
				<user-phone-input :length="codeLength" @updated="updated" ref="inputsComponent" />
			</div>
			<div class="text-center" v-if="validationExternal.length > 0 || validation.code.$errors.length > 0">
				<div class="invalid-feedback d-block" v-for="error of validation.code.$errors" v-bind:key="error.$uid">{{ error.$message }}</div>
				<div class="invalid-feedback d-block" v-for="(error, index) in validationExternal" v-bind:key="index">{{ error }}</div>
			</div>
			<div>
				<button type="submit" class="btn btn-dark d-flex align-items-center w-100" :disabled="sendingCode || submitting">
					<div class="flex-fill me-n5">Continuar</div>
					<span class="icon icon-sm ps-2">
						<i class="bi bi-chevron-right"></i>
					</span>
				</button>
			</div>
			<div>
				<button type="button" class="btn btn-outline-warning w-100" :disabled="sendingCode || !canRequestCode" @click="requestCode">{{ resendDescription }}</button>
			</div>
		</form>
	</layouts-identify>
</template>

<script>
	import { toRefs, computed, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
	import OtpRepository from '@/repositories/OtpRepository';
	import LocationRepository from '@/repositories/LocationRepository';
	import composableOtp from '@/composables/otp';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';
	import moment from 'moment';

	export default {
		setup() {
			const { query: { target_type, target }} = useRoute();
			const { form, timer, codeLength, sendingCode, inputsComponent, canRequestCode, resendDescription, validation } = composableOtp();
			const { redirectBack, redirectNext } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();

			async function submit() {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				store.dispatch('auth/otp', {
					target_type: target_type,
					target: target,
					received_code: form.code,
					issued_for: 'recovery_access'
				}).then(setUser).catch(catchError);
			}

			const setUser = () => {
				store.dispatch('auth/me').then(setLocation).catch(catchError);
			}

			const setLocation = () => {
				LocationRepository.default().then(response => {
					if(response.data) {
						store.dispatch('location/setAddress', {
							id: response.data.address_id,
							description: response.data.pivot.description,
							country: response.data.country,
							administrative_area_level_1: response.data.administrative_area_level_1,
							administrative_area_level_2: response.data.administrative_area_level_2,
							administrative_area_level_3: response.data.administrative_area_level_3,
							locality: response.data.locality,
							sublocality: response.data.sublocality,
							route: response.data.route,
							street_number: response.data.street_number,
							postal_code: response.data.postal_code,
							division: response.data.division,
							geolocation: {
								lat: response.data.geolocation.coordinates[0],
								lng: response.data.geolocation.coordinates[1]
							}
						}).then(redirectNext).catch(catchError);
					}else redirectNext();
				}).catch(catchError);
			}

			const targetFormatted = computed(() => {
				if(target_type == 'phone' || isValidPhoneNumber(target)) return parsePhoneNumber(target).formatNational();
				return target;

			});

			const updated = (data) => {
				form.code = data;
				validationExternal.value = [];
			}

			const requestCode = () => {
				if(!canRequestCode.value) return;

				inputsComponent.value.setCode(null);
				sendingCode.value = true;

				OtpRepository.create({
					target_type: target_type,
					target: target,
					issued_for: 'recovery_access'
				}).then((response) => {
					timer.count = response.data.expires_in;
					timer.expires_in = moment(response.data.expires_in * 1000);
					validationExternal.value = [];
					sendingCode.value = false;
				}).catch((e) => {
					catchError(e, true);
					sendingCode.value = false;
					router.push({name: 'identify.index'});
				});
			}

			var ac = null;

			onMounted(() => {
				if(!target_type || !target) return redirectBack();

				requestCode();

				if(target_type == 'phone' && 'OTPCredential' in window) {
					ac = new AbortController();

					navigator.credentials.get({
						otp: { transport: ['sms'] },
						signal: ac.signal
					}).then(otp => {
						inputsComponent.value.setCode(otp.code);
						submit();
					});
				}
			});

			onBeforeUnmount(() => {
				if(ac) ac.abort();
			});

			return { ...toRefs(form), target_type, inputsComponent, codeLength, validation, validationExternal, targetFormatted, updated, submitting, submit, sendingCode, canRequestCode, requestCode, resendDescription };
		}
	}
</script>

<style scoped>
	.bi-phone {
		font-size: 4rem;
	}
</style>